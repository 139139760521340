<template>
  <table class="links_table text-left flex-1 h-full w-full">
    <thead class="bg-[#F4F6FA] text-[#757A8A] font-semibold text-[0.875rem] font-poppins">
    <tr>
      <td class="py-3 pl-3 text-left w-[20%]">Browser
      </td>
      <td class="py-3 text-center w-[20%]"
          @click.prevent="browser_sorting = (browser_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
        Clicks
        <i v-if="browser_sorting==='clicks_asc'"
           class="first table_filter_caret fas fa-caret-up"></i>
        <i v-if="browser_sorting==='clicks_desc'"
           class="table_filter_caret fas fa-caret-down"></i>
      </td>
      <td class="py-3 text-center w-[20%]"
          @click.prevent="browser_sorting = (browser_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'"
      >
        Unique Clicks

        <i v-if="browser_sorting==='unique_clicks_asc'"
           class="first table_filter_caret fas fa-caret-up"></i>
        <i v-if="browser_sorting==='unique_clicks_desc'"
           class="table_filter_caret fas fa-caret-down"></i>

      </td>
      <td v-if="!forBio" class="py-3 text-center w-[20%]"
          @click.prevent="browser_sorting = (browser_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'"
      >
        Conversions
        <i v-if="browser_sorting==='conversions_asc'"
           class="first table_filter_caret fas fa-caret-up"></i>
        <i v-if="browser_sorting==='conversions_desc'"
           class="table_filter_caret fas fa-caret-down"></i>
      </td>
      <td v-if="!forBio" class="py-3 text-center w-[20%]">Conv. rate

      </td>
    </tr>
    </thead>

    <tbody class="">
    <tr v-if="statistics.aggregations.visited_from_browsers.buckets.length == 0">
      <td colspan="9">
        <div class="col">
          <p class="no-rows text-center">
            <no-events-available></no-events-available>
          </p>
        </div>
      </td>
    </tr>
    <template v-else>
      <tr v-for="analytic in getBrowserList" class="border-b border-[#ECEEF5] ">
        <td class="text-capitalize flex justify-start items-center py-4 pl-3 gap-x-2 w-[20%]">
          <img :src="fetchBrowserIcon(analytic.key)" class="h-[30px] w-[30px]">
          <div>
            {{ computeKeyAnalytics(analytic.key) }}
          </div>
        </td>
        <td class="w-[20%] text-center py-4">{{ analytic.doc_count }}</td>
        <td class="w-[20%] text-center py-4">{{ analytic.unique_clicks.value }}</td>
        <td class="w-[20%] text-center py-4" v-if="!forBio">
          {{ analytic.clicked_conversions.doc_count }}
        </td>
        <td class=" w-[20%]  text-center py-4" v-if="!forBio">

          {{ Math.round((analytic.clicked_conversions.doc_count / analytic.doc_count) * 100) }}%
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>
<script>
import orderBy from 'lodash/orderBy'

export default ({
  data() {
    return {
      browser_sorting: 'clicks_desc'
    }
  },
  props: {
    statistics: {},
    forBio: false
  },
  computed: {
    getBrowserList() {
      if (this.statistics.aggregations.visited_from_browsers.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.browser_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_browsers.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    }
  }
})

</script>
